import React, { Component } from 'react';
import moment from 'moment';

  class TrelloCardDisplay extends Component {

  render() {
    return (
      <div className="trellocards-card">
        <h1 className="trellocards-cardtitle">{this.props.name}</h1>
        <div className="trellocards-cardmeta">
          {this.props.labels.map(label => {
            const classes = `trellocards-carddept trellocards-carddept__${label.color}`
            return (
              <span key={label.id} className={classes}>{label.name}</span>
            )
          })}
          <span className="trellocards-duedate">{this.props.due !== null ? moment(this.props.due).format("MMM Do YYYY") : "No due date"}</span>
          {this.props.members.map(member => {
            const alt = `${member.fullName} is working on this job`
            const avatar = member.avatarUrl === null ? `https://eu.ui-avatars.com/api/?name=${member.fullName}` : `${member.avatarUrl}/50.png`
            return (
              <img className="trellocards-cardmember" key={member.id} src={avatar} alt={alt} title={alt} />
            )
          })}
        </div>
        <div className="trellocards-description">
          {this.props.desc}
        </div>
      </div>
    )
  }

}

export default TrelloCardDisplay;