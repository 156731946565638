import React, { Component } from 'react';

class ThanksMsg extends Component {
  render() {
    
    return (
      <div className="message-thanks">
        <h1>Thanks!</h1>
        <h2>We've now received your request</h2>
        <p>Thanks for your request, if you have any files or images to go with this job you can email them to us at <a href="mailto:marketing@derbyunion.co.uk">marketing@derbyunion.co.uk</a> now.</p>

        <p>If your request includes design work you'll receive a proof to <strong>{this.props.email}</strong> as soon as we can. Please acquaint yourself with our proofing procedures – you'll need to respond to us with confirmation you're happy with the design or if there's any changes needed in order for us to produce your design for your deadline.</p>

        <div className="formbuttons">
          <button className="button button__submit" onClick={() => {this.props.changeDisplay("form")}}>Submit another request</button>
          <a href="https://source.unsplash.com/1600x900/?kitten" type="reset" className="button button__reset">Show me kittens</a>
        </div>
      </div>
    )
  }
}

export default ThanksMsg;