import React, { Component } from 'react';

import TrelloCardDisplay from './TrelloCardDisplay'

class DisplayJobs extends Component {

  render() {
    const trelloCards = []
    this.props.cards.forEach(card => {
      //console.log("Processing Card", card);
      trelloCards.push(
        <TrelloCardDisplay
          key={card.id}
          id={card.id}
          name={card.name}
          labels={card.labels}
          due={card.due}
          desc={card.desc}
          members={card.members}
          custom={card.customFieldItems}
      />)
    })
    
    const prefilter = trelloCards.filter( a => {
      return !(a.props.labels.some(b => b.name === "Internal") || a.props.labels.length === 0)
    })

    const filteredCards = prefilter.filter( a => {
      return this.props.filters.department !== "" ? a.props.labels.some(b => b.id === this.props.filters.department) : true
    }).filter( a => {
      return this.props.filters.originator !== "" ? a.props.custom.some(b => b.value.text === this.props.filters.originator) : true
    }).sort( (a,b) => {
      return new Date(1000*parseInt(a.props.id.substring(0,8),16)) < new Date(1000*parseInt(b.props.id.substring(0,8),16)) ? 1 : -1
    })

    return (
      <div className="trellocards">
        <h1>Existing Jobs <span>({filteredCards.length}/{prefilter.length})</span></h1>
        <div className="trellocards-cards">
          {filteredCards}
        </div>
      </div>
    );
  }
}

export default DisplayJobs;