import React, { Component } from 'react';
import SentryErrorBoundary from './Error';

import moment from 'moment';
import turndownService from 'turndown';

import {version} from '../../package.json';
import DisplayJobs from './DisplayJobs';
import RequestForm from './RequestForm';
import Thanks from './Thanks';

class App extends Component {

  state = {
    mode: "live",
    display: "form",
    trello: {
      key: "9a007b0e436866efa6cc7ca8c1f8e5ba",
      token: "c3a3e98464716ea86893d8308851b318d4568ee41b946721c19e1bc7943aafa2",
      board: {
        dev: "5dbab91a27d0fa8fbc83b874",
        live: "5b041ca264eb32052df90ae0"
      },
      list: {
        dev: "5dbab923e84eef4da9d7251c",
        live: "5b041ca264eb32052df90ae1"
      },
      custom: {
        contact: {
          dev: "5dbab982c3416c6d2828bebd",
          live: "5dbab64a54575c1ad5592722"
        },
        from: {
          dev: "5dbafaecac9d1f40d74edf63",
          live: "5dbafb1f4687ee5cecaf395f"
        }
      }
    },
    cards: [],
    form: {
      name: "",
      email: "",
      department: "",
      deadline: null,
      isDisabled: false,
      isLead: false,
      isEmpty: false,
      jobtitle: "",
      jobrequest: "",
      isValid: false,
      isValidated: false,
    },
    refs: {
      dateRef: React.createRef()
    }
  }

  componentDidMount() {
    window.addEventListener("resize", () => {
      document.getElementsByClassName("trellocards")[0].style.maxHeight = document.getElementsByClassName("request-form")[0].offsetHeight
    });

    const localStorageRef = localStorage.getItem('marketing-request');
    if (localStorageRef) {
      let form = { ...this.state.form };
      form = JSON.parse(localStorageRef);
      if (form.deadline !== null) form.deadline = new Date(form.deadline);
      this.setState({ form });
    }

    this.fetchCards()
  }

  componentDidUpdate() {
    localStorage.setItem('marketing-request', JSON.stringify(this.state.form));
  }

  formatName(name) { return name.replace(/\b(\w)/g, s => s.toUpperCase()); }
  formatTitle(title) { return title.charAt(0).toUpperCase() + title.slice(1); }

  handleDayChange = (deadline, modifiers, dayPickerInput) => {
      const input = dayPickerInput.getInput();

      console.log(dayPickerInput.getInput().value)
      if (typeof deadline === 'undefined') {
        dayPickerInput.getInput().setCustomValidity("Not a date");
      } else {
        dayPickerInput.getInput().setCustomValidity("");
      }

      const form = { ...this.state.form };
      form.deadline = deadline;
      form.isDisabled = modifiers.disabled === true;
      form.isLead = modifiers.leaddays === true;
      form.isEmpty = !input.value.trim;
      this.setState({ form });
  }

  handleChange = e => {
      const form = { ...this.state.form };
      form[e.currentTarget.name] = e.currentTarget.value;
      //console.log("Newstate:", form);
      this.setState({ form });
  }

  handleChangeEditor = data => {
      const form = { ...this.state.form };
      form['jobrequest'] = data;
      //console.log("Newstate:", form);
      this.setState({ form });
  }

  formDisplayChange = status => {
      this.setState({ formStatus: status });
  }

  formValidatedChange = status => {
      console.log("validated change")
      const form = { ...this.state.form };
      form.isValidated = status;
      this.setState({ form });
  }

  submitForm = e => {
      console.log("Form submitted")
      this.formDisplayChange("submitted");

      e.preventDefault();
      this.newRequest();

      // Validation
      // console.log("Description length:", this.state.form.jobrequest.length)
      // console.log("Deadline:", this.state.form.deadline);
      // console.log("Deadline type:", typeof this.state.form.deadline);
      // console.log("Validity Return is:", e.currentTarget.checkValidity());
  }



  resetForm = () => {
      const form = {};
          form.name = this.state.form.name;
          form.email = this.state.form.email;
          form.department = (this.state.form.department.length !== "") ? this.state.form.department : "";
          form.deadline = null;
          form.isDisabled = false;
          form.isLead = false;
          form.isEmpty = false;
          form.jobtitle = "";
          form.jobrequest = "";
          form.isValid = false;
          form.isValidated = false;
      this.setState({ form });
  }

  changeDisplay = (displaystate) => {
    const display = displaystate;
    if (displaystate === "form") { this.resetForm() }
    this.setState({ display });
  }

  turndownHolder = new turndownService({
    headingStyle: 'atx',
    hr: '---',
    bulletListMarker: '-',
    codeBlockStyle: 'fenced',
  });

  toMarkdown = data => {
      const md = this.turndownHolder.turndown(data);
      //const hashbastards = md.replace(/^#/mg, ('\\'+'#'));
      const hashbastards = md.replace(/^#/mg, ('\\#'));
      console.log("md",hashbastards);
      return hashbastards;
  }

  newRequest = () => {

    const leadtime = moment(this.state.form.deadline).diff(moment(), 'days') + 1;

    const card = {
      name: this.formatTitle(this.state.form.jobtitle),
      desc: `> **From:** ${this.formatName(this.state.form.name)} ( [${this.state.form.email.toLowerCase().split('@').join("\\@")}](mailto:${this.state.form.email.toLowerCase()}?subject=Re:%20Marketing%20Request%20-%20${encodeURIComponent(this.formatTitle(this.state.form.jobtitle))}) )
> **Submitted on:** ${moment().format('Do MMMM YYYY, h.mma')}  *(${leadtime} days notice)*

___

${this.toMarkdown(this.state.form.jobrequest)}`,
      pos: "top",
      due: moment(this.state.form.deadline).toISOString(),
      dueComplete: false,
      idList: (this.state.mode === "live") ? this.state.trello.list.live : this.state.trello.list.dev,
      idLabels: (this.state.mode === "live") ? this.state.form.department : "5dbab91a8bdee58e0d070a11",
      key: this.state.trello.key,
      token: this.state.trello.token,
    }


    const esc = encodeURIComponent;
    const query = Object.keys(card)
        .map(k => esc(k) + '=' + esc(card[k]))
        .join('&');
    console.log("URL", `https://api.trello.com/1/cards?${query}`);
    
    this.formDisplayChange("working");

    fetch(`https://api.trello.com/1/cards?${query}`, {  
      method: 'POST',
    })
    .then(response => {
      if (response.status !== 200) {
        console.error(`Looks like there was a problem creating the card. Status Code: ${response.status}`, response.json());
        return;
      }
      response.json().then(data => {
        console.log("Trello: Success creating card -", data);
        Promise.all([
          fetch(`https://api.trello.com/1/card/${data.id}/customField/${(this.state.mode === "live") ? this.state.trello.custom.contact.live : this.state.trello.custom.contact.dev}/item?token=${this.state.trello.token}&key=${this.state.trello.key}`, { body: JSON.stringify({value: {"text": `${this.state.form.email.toLowerCase()}`}}), method: 'PUT', headers: {'content-type': 'application/json'}}),
          fetch(`https://api.trello.com/1/card/${data.id}/customField/${(this.state.mode === "live") ? this.state.trello.custom.from.live : this.state.trello.custom.from.dev}/item?token=${this.state.trello.token}&key=${this.state.trello.key}`, { body: JSON.stringify({value: {"text": `${this.formatName(this.state.form.name)}`}}), method: 'PUT', headers: {'content-type': 'application/json'}})
        ])
        .then(data => {
          console.log("Trello: Success adding custom fields -", data);
        })
        .catch(error => {
          console.error("Trello: Error adding custom fields -", error);
        })
        // Success submitting
        // this.resetForm();
        this.changeDisplay("thanks");
        this.fetchCards();
        localStorage.removeItem('marketing-request');
      })
    })
  }

  fetchCards() {
    fetch(`https://api.trello.com/1/boards/${this.state.trello.board.live}/cards/open?key=9a007b0e436866efa6cc7ca8c1f8e5ba&token=c3a3e98464716ea86893d8308851b318d4568ee41b946721c19e1bc7943aafa2&members=true&fields=name,desc,due,labels,idList&customFieldItems=true`, { mode: 'cors' })
    .then(response => {
      if (response.status !== 200) {
        console.error(`Looks like there was a problem. Status Code: ${response.status}`, response)
        return;
      }
      response.json().then(cards => {
        //console.log(cards);
        this.setState({cards})
      })
    })
  }

  render() {
    return (
      <div className="app">
        <div className="version">v{version}</div>
        <div className="wrap">
        
          <div className="request">
            
            <SentryErrorBoundary>
              {this.state.display === "form" ? 
              <RequestForm
                formstate={this.state.form}
                submitForm={this.submitForm}
                formValidatedChange={this.formValidatedChange}
                handleChange={this.handleChange}
                handleChangeEditor={this.handleChangeEditor}
                handleDayChange={this.handleDayChange}
                selectedDay={this.state.form.deadline}
                resetForm={this.resetForm}
                dateRef={this.state.refs.dateRef}
              />
              :
              <Thanks
                changeDisplay={this.changeDisplay}
                email={this.state.form.email}
              />
              }
            </SentryErrorBoundary>

            <SentryErrorBoundary>
              <DisplayJobs
                board={this.state.trello.board.live}
                fetchCards={this.fetchCards}
                cards={this.state.cards}
                filters={{
                  department: this.state.form.department,
                  originator: this.state.form.email
                }}
              />
            </SentryErrorBoundary>

          </div>

          

        </div>
      </div>
    );
  }
}

export default App;
