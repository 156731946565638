import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import {version} from '../package.json';

import * as Sentry from '@sentry/browser';

import App from './components/App';
import * as serviceWorker from './serviceWorker';

Sentry.init({dsn: "https://43b2130efcb24e2fadb764df7c03de88@sentry.io/1818202"});

Sentry.configureScope(function(scope) {
  scope.setTag("app_version", version);
});


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
