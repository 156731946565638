import React, { Component } from 'react';

import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { formatDate, parseDate } from 'react-day-picker/moment';
import moment from 'moment';

const leadtime = {
    before: moment().add(28, 'days').toDate()
}

const modifiers = {
    leaddays: {
        from: new Date(),
        to: moment().add(27, 'days').toDate()
    }
}

class RequestForm extends Component {

  dateRef = React.createRef();

  componentDidUpdate() {
    if (this.props.formstate.department === "") {
      document.getElementById("department").style.color = "#808080";
    } else {
      document.getElementById("department").style.color = "black";
    }
  }

  render() {
    
    return (
      <form className={this.props.formstate.isValidated ? "request-form  form-validated" : "request-form  form-unvalidated"} onSubmit={this.props.submitForm}>
        <h1>Submit a New Job</h1>
          <div className="formgroup">
            <label htmlFor="name">Your name</label>
            <input 
              required
              type="text"
              id="name"
              name="name"
              className="formcontrol"
              placeholder="Joe Bloggs"
              autoComplete="name"
              onChange={this.props.handleChange}
              value={this.props.formstate.name}
            />
            <div className="invalid-field">We need your name to know who's requesting the job</div>
          </div>
          <div className="formgroup">
            <label htmlFor="email">Your email</label>
            <input
              required
              type="email"
              id="email"
              name="email"
              className="formcontrol"
              placeholder="joe.bloggs@derbyunion.co.uk"
              autoComplete="email"
              onChange={this.props.handleChange}
              value={this.props.formstate.email}
            />
            <div className="invalid-field">We need an email address to contact you about this job</div>
          </div>
          <div className="formgroup">
            <label htmlFor="department">Your department</label>
            <div className="select">
              <select required id="department" value={this.props.formstate.department} name="department" placeholder="Select a Department" className="formcontrol" onChange={this.props.handleChange} >
                <option value="" hidden >Blogs and Marketing</option>
                <option value="5c419b500c1f20466739a36b">Admin &amp; Finance</option>
                <option value="5b0436dc102ba87352c730b0">Advice Service</option>
                <option value="5b0436c0e3dd3066af0975ad">Commercial Services</option>
                <option value="5b041ca264eb32052df90aec">Employability &amp; Volunteering</option>
                <option value="5b041ca264eb32052df90af0">Further Education/BLC</option>
                <option value="5b041ca264eb32052df90aef">Officers &amp; Senior Management</option>
                <option value="5b041ca264eb32052df90aed">Student Voice</option>
                <option value="5b041ca264eb32052df90aee">Student Activities</option>
              </select>
              <div className="invalid-field">Let us know who department this job is for</div>
            </div>
          </div>
          <div className="formgroup">
            <label htmlFor="deadline">Deadline</label>
            <DayPickerInput
              ref={this.props.dateRef}
              required
              id="deadlineDate"
              formatDate={formatDate}
              parseDate={parseDate}
              format="Do MMMM YYYY"
              placeholder={moment().add(28, 'days').format("Do MMMM YYYY")}
              value={moment(this.props.formstate.deadline).isBefore(moment().subtract(7,'days')) ? new Date(moment().add(4, 'weeks')) : this.props.formstate.deadline}
              clickUnselectsDay={true}
              onDayChange={this.props.handleDayChange.bind(this)}
              showOverlay={false}
              inputProps={{
                required: "required"
              }}
              dayPickerProps={{
                  initialMonth: new Date(),
                  disabledDays: [leadtime, { daysOfWeek: [0, 6] }],
                  numberOfMonths: 2,
                  fromMonth: new Date(),
                  modifiers: modifiers
              }}
            />
            <div className={
              (this.props.hasOwnProperty("deadline") && this.props.formstate.deadline !== "") ? "valid-field--date" : "invalid-field--date"
            }>Please choose a date you need this job by</div>
          </div>
          <div className="formgroup">
            <label htmlFor="jobtitle">Request title</label>
            <input required type="text" id="jobtitle" name="jobtitle" className="formcontrol" placeholder="How to do a request – A5 flyer" onChange={this.props.handleChange} value={this.props.formstate.jobtitle} />
            <div className="invalid-field">Please choose a descriptive name for this job</div>
          </div>
          <div className="formgroup">
            <label htmlFor="jobrequest">Request text</label>
            <CKEditor
            className="formcontrol"
            editor={ClassicEditor}
            data={this.props.formstate.jobrequest}
            config={{
                toolbar: [
                    'heading',
                    'bold',
                    'italic',
                    'bulletedList',
                    'numberedList',
                    'blockQuote',
                ],
                placeholder: "All the information to do the job goes here. There can't be too much, but there can be too little. So go nuts and tell us EVERYTHING!"
            }}
            onInit={ editor => {
                // You can store the "editor" and use when it is needed.
                //console.log( 'Editor is ready to use!', editor );
                //console.log( Array.from( editor.ui.componentFactory.names() ) );
            } }
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                //console.log( { event, editor, data } );
                this.props.handleChangeEditor(data);
            } }
          />
            <div className="invalid-field">Let us know what you want us to do</div>
          </div>

          <div className="formbuttons">
            <button type="submit" className="button button__submit" >Submit this request</button>
            <button type="reset" className="button button__reset" onClick={this.props.resetForm}>Reset the form</button>
          </div>
      </form>
    );

  }
}

export default RequestForm;